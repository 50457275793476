import '../../App.css';
import NavBar from "../shared/navBar";
import BreadCrumb from '../shared/breadcrumb';
import NoInternetMessage from '../shared/noInternetMessage';
import Footer from '../shared/footer';
import BottomNav from '../shared/bottomNav';

function ItemPageLoading() {

  // loading state before data arrives
  return (
    <div className="App item-page">
    <NavBar></NavBar>
    <NoInternetMessage />
    <div className='main-app-container'>
      <header className="app-header">
        
        <div className='item-page-header-flex-container'>
          <div className='item-page-header-text'>
            
          </div>
        </div>
      </header>
      
    </div>
    <Footer></Footer>
    <BottomNav></BottomNav>
  </div>
  );

}

export default ItemPageLoading;
